import { Module } from 'vuex';
import { navigationGetters } from '@/store/navigation/getters';
import { actions } from '@/store/navigation/actions';
import { mutations } from '@/store/navigation/mutations';
import { Link, Navigation } from '../../../types/contentful-api';
import { DKNavigationItem } from '@/store/navigation/models/DKNavigationItem';
import { NavigationTreeItem } from '@/store/navigation/models/NavigationTreeItem';
import { NavbarTransparentElement } from '@/models/navigation/NavbarTypes';

export interface NavigationState {
  ready: boolean;
  navigations: { data: Navigation[] };
  navigationItems: { data: DKNavigationItem[] };
  links: { data: Link[] };
  currentNavigationTreeItem: NavigationTreeItem | null;
  currentLink: Link | null;
  searchExpanded: boolean;
  sidebarExpanded: boolean;
  locationSwitcherExpanded: boolean;
  languageSwitcherExpanded: boolean;
  activeNavLinkId: string | null;
  transparentElement: NavbarTransparentElement;
  navbarHidden: boolean;
}

const navigationModule: Module<NavigationState, any> = {
  state: () => ({
    ready: false,
    navigations: {
      data: [],
    },
    navigationItems: {
      data: [],
    },
    links: {
      data: [],
    },
    currentLink: null,
    currentNavigationTreeItem: null,
    searchExpanded: false,
    sidebarExpanded: false,
    locationSwitcherExpanded: false,
    languageSwitcherExpanded: false,
    activeNavLinkId: null,
    transparentElement: null,
    navbarHidden: false,
  }),
  getters: navigationGetters,
  actions,
  mutations,
  namespaced: true,
};

export default navigationModule;
